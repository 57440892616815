import { Box } from "@chakra-ui/layout"
import React from "react"

import ziptiTerms from "../images/ziptiTerms.pdf"

const TermsPage: React.FC = () => {
  return (
    <Box h="100vh" w="100vw">
      <embed src={ziptiTerms} height="100%" width="100%" />
    </Box>
  )
}

export default TermsPage
